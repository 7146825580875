nav .m-menu__checkbox {
    display: none;
  }
  
  nav label.m-menu__toggle {
    cursor: pointer;
  }
  
  nav .m-menu {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 450px;
    width: calc(100vw - 30px);
    height: 100%;
    -moz-transform: translate3d(-450px, 0, 0);
    -o-transform: translate3d(-450px, 0, 0);
    -ms-transform: translate3d(-450px, 0, 0);
    -webkit-transform: translate3d(-450px, 0, 0);
    transform: translate3d(-450px, 0, 0);
    -moz-transition: transform 0.35s;
    -o-transition: transform 0.35s;
    -webkit-transition: transform 0.35s;
    transition: transform 0.35s;
    z-index: 9999;
    overflow: hidden;
    background-color: #F4F5FF;
  }
  
  nav .m-menu__overlay {
    background-color: rgba(103, 103, 103, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    z-index: 9999;
    display: none;
  }
  
  nav .m-menu__header {
    padding: 0 10px;
    height: 50px;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    -ms-flex-pack: space-around;
    -webkit-align-items: center;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.15);
  }
  
  nav .m-menu__header span {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
  }
  
  nav .m-menu .m-menu {
    -moz-transform: translate3d(480px, 0, 0);
    -o-transform: translate3d(480px, 0, 0);
    -ms-transform: translate3d(480px, 0, 0);
    -webkit-transform: translate3d(480px, 0, 0);
    transform: translate3d(480px, 0, 0);
  }
  
  nav .m-menu ul {
    height: 100%;
    overflow-y: auto;
    margin: 0;
    padding: 0 0 15%;
    list-style: none;
  }
  
  nav .m-menu ul li > a,
  nav .m-menu ul li label {
    display: block;
    text-align: left;
    padding: 0 15px;
    line-height: 47px;
    text-decoration: none;
    color: #111;
    font-weight: 500;
    cursor: pointer;
    font-size: 1rem;
    border-bottom: 1px solid rgba(0,0,0,0.15);
    position: relative;
  }
  
  nav .m-menu ul li label.a-label__chevron::after {
    content: "";
    position: absolute;
    display: inline-block;
    height: 10px;
    width: 10px;
    border-color: #111;
    border-style: solid;
    border-width: 2px 2px 0 0;
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 50%;
    margin-top: -5px;
    right: 16px;
  }
  
  nav .m-menu ul li .-invisible {
    border-bottom: 0;
  }
  
  nav .m-menu .m-menu label.m-menu__toggle {
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    border-bottom: 0;
    padding: 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  
  nav .m-menu__checkbox:checked ~ .m-menu__overlay {
    display: block;
  }
  
  nav .m-menu__checkbox:checked ~ .m-menu {
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }