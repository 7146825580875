a, button, input, select, textarea{ transition:all 0.25s ease-in 0s; -webkit-transition:all 0.25s ease-in 0s; -moz-transition:all 0.25s ease-in 0s; outline:none;}
a:focus, button:focus{ border:0; outline:none;}
img { max-width:100%; vertical-align:middle; border:none;}
/*html, body{ overflow-x: hidden;}*/
body, button, input, select, optgroup, textarea{ color: #111111; font-size: 14px; word-wrap: break-word; font-family: "Work Sans", sans-serif; font-style: normal; font-weight: 400;}

/*==========================Page=Css=Start====================================*/
.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container{ max-width: 1660px; padding-left: 20px; padding-right: 20px;}
.gx-6{ --bs-gutter-x: 2.5rem;}

@keyframes textFader{
  0% {
    opacity: 0;
  }
  11% {
    opacity: 1;
  }
  22% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.header{ border-bottom: 1px solid #F4F5FF;}
.top_bar{ background: #FEB41D;}
.top_bar ul{ margin: 0; padding: 0; list-style: none; display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between;}
.top_bar ul li{ line-height: 36px;}
.top_bar ul li a{ color: #111111; text-decoration: none;}
.top_bar ul li a:hover{ color: #fff;}

.header_inner{ padding: 16px 0;}
.header_right > ul{ margin: 0; padding: 0; list-style: none; display: flex; flex-wrap: wrap; align-items: center; justify-content: flex-end;}
.header_right > ul > li{ padding-left: 35px; position: relative;}
.header_right > ul > li > a{ display: flex; align-items: center; text-decoration: none; position: relative;}
.header_right > ul > li > a .icon{ width: 40px; height: 40px; background: #363B73; border-radius: 30px; display: flex; align-items: center; justify-content: center; transition:all 0.25s ease-in 0s; -webkit-transition:all 0.25s ease-in 0s;}
.header_right > ul > li > a .text{ color: #4C4C4C; font-size: 16px; padding-left: 10px;}
.header_right > ul > li > a:hover .icon{ background: #FEB41D;}
.header_right > ul > li > a .cart_count{ display: flex; align-items: center; justify-content: center; border-radius: 30px; background-color: #FEB41D; font-size: 14px; color: #fff; font-weight: 600; width: 24px; height: 24px; position: absolute; left: 25px; top: -15px; z-index: 1}

.afterLogin_menu{ text-align: right; font-size: 16px; padding-bottom: 23px;}
.afterLogin_menu.mobile{ display: none;}
.afterLogin_menu a{ color: #111; text-decoration: none; padding: 0 9px;}
.afterLogin_menu a:hover{ color: #FEB41D;}
.navbar_toggler{ display: none;}

.menu_wrap{ background: #F4F5FF;}
.menu_wrap .navbar{ padding:0;}
.menu_wrap .navbar .navbar-nav{ width: 100%; justify-content: space-between;}
.menu_wrap .navbar .navbar-nav > li > a{ color:#111111 !important; font-size: 16px; text-decoration:none; font-weight:500; line-height: 59px; padding: 0 15px;}
.menu_wrap .navbar .navbar-nav > li > a:hover, .menu_wrap .navbar .navbar-nav > li.active > a{ color:#FEB41D !important;}

/* .menu_wrap .navbar .navbar-nav > li:hover .dropdown-menu{ display: block;} */
.menu_wrap .navbar .navbar-nav > li .dropdown-menu{ width: 100%; left: 0; padding: 0; border-radius: 0; margin-top: 0; background: #F5F6FF; border: 0; min-height: 700px; display: block;}

.mega_menu_content ul{ margin: 0; padding: 0; list-style: none; display: flex; flex-wrap: wrap;}
.mega_menu_content ul.level1 > li{ display: flex; flex-wrap: wrap; width: 100%;}
.mega_menu_content ul.level1 > li > a.has_child{ width: 297px; display: block; color: #fff; font-weight: 500; text-decoration: none; padding: 12px 25px; background: #363B73; position: relative;}
.mega_menu_content ul.level1 > li:hover > a.has_child{ background: #F5F6FF; color: #111;}
.mega_menu_content ul.level1 > li.active > a.has_child{ background: #F5F6FF; color: #111;}
.mega_menu_content ul.level1 > li > a.has_child svg{ position: absolute; right: 20px; margin-top: 3px;}

.mega_menu_content ul.level1 > li > .mega_menu{ width: calc(100% - 297px); position: absolute; left: 297px; top: 0; z-index: 99; visibility: hidden; opacity: 0; padding: 0 0 30px; transition: opacity .4s,visibility .4s; -webkit-transition: opacity .4s,visibility .4s;}
/*.mega_menu_content ul.level1 > li:hover .mega_menu,*/ .mega_menu_content ul.level1 > li.active .mega_menu{ opacity: 1; visibility: visible;}

.mega_menu_content ul.level2 li{ width: 16.66%; padding-top: 35px;}
.mega_menu_content ul.level2 li a{ display: flex; flex-direction: column; align-items: center; text-decoration: none; text-align: center;}
.mega_menu_content ul.level2 li a img{ border-radius: 10px; border: 1px solid #AAAAAA; max-width: 129px;}
.mega_menu_content ul.level2 li a .menu_category_name{ color: #01000D; padding-top: 20px;}

.menu_products_inner{ display: flex; flex-wrap: wrap; padding: 10px;}
.menu_products_inner .mpb{ width: 50%; padding: 10px;}
.menu_products_inner .mpb figure{ border: 1px solid #ccc; background: #fff; border-radius: 10px; padding: 5px; margin-bottom: 5px;}
.menu_products_inner .mpb a{ padding: 0; text-align: center; font-weight: 400; display: block; color: #111; text-decoration: none; font-size: 16px;}
.menu_products.mm-spn--open li:before{ display: none;}

.search_icon{ position: relative; z-index: 2;}
.searchingform{ opacity:0; visibility:hidden; position:absolute; z-index:1; right: 1px; -webkit-transition:all 300ms; -moz-transition:all 300ms; transition:all 300ms;}
.searchingform.show{ display:block; opacity:1; visibility:visible;}
.searchingform input{ background: #fff; border: 1px solid #363B73; color: #4C4C4C; padding: 11px 20px; line-height: normal; border-radius: 30px; width: 22vw;}
.searchingform .search_close_icon{ position: absolute; right: 55px; top: 0; bottom: 0; display: flex; align-items: center; cursor: pointer;}
.searchingform .search_close_icon img{ max-width: 14px;}

.quick_search_icon{ position: relative; z-index: 2;}
.Quicksearchingform{ opacity:0; visibility:hidden; position:absolute; z-index:1;  -webkit-transition:all 300ms; -moz-transition:all 300ms; transition:all 300ms;}
.Quicksearchingform.show{ display:block; opacity:1; visibility:visible;}
.Quicksearchingform input{ background: #fff; border: 1px solid #363B73; color: #4C4C4C; padding: 11px 20px; width: 100%; line-height: normal; border-radius: 30px;  padding-right: 45px;}
.Quicksearchingform .quick_search_icon{ position: absolute; right: 55px; top: 0; bottom: 0; display: flex; align-items: center; cursor: pointer;}
.Quicksearchingform .quick_search_icon img{ max-width: 14px;}
.quick_button {text-align: end;}
.middle_wrapper{ min-height: calc(100vh - 791px);}
.QuickSearchItem{ position: relative; display: inline-block; margin-top:15px; width: 10vw;} 
.QuickSearchItem .icon{ width: 40px; height: 40px; background: #363B73; border-radius: 30px; display: flex; align-items: center; justify-content: center; transition: all 0.25s ease-in 0s; -webkit-transition: all 0.25s ease-in 0s;}
.QuickSearchItem .quick_search_icon{ position: absolute; right: 0px; top: 0px; background-color:transparent; padding: 0px !important;}
.QuickSearchItem .Quicksearchingform{ position: static; padding: 0px;} 
.QuickSearchItem .icon:hover{ background: #FEB41D;}

.banner{ background: #363B73 url(https://davenportimage.s3.amazonaws.com/banner-bg.svg) center bottom no-repeat; background-size: contain;}
.bannerBgImg{ background-position: center center; background-repeat: no-repeat; background-size: cover; padding: 6px 0; position: relative; z-index: 1;}
/* .bannerBgImg::before{ content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; background: #363B73; mix-blend-mode: multiply; opacity: 0.7; z-index: -1;} */
/* #banner .item{ display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; aspect-ratio: 1/0.25;} */
#banner .item{ display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; aspect-ratio: 1/0.2111;}
.banner_content{ width: 45%;}
.banner_content h4{ font-size: 20px; font-weight: 700; color: #fff; margin-bottom: 15px;}
.banner_content h2{ font-size: 48px; font-weight: 700; color: #fff; margin-bottom: 41px; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; line-clamp: 2;  -webkit-box-orient: vertical;}
.banner_img{ width: 36%;}
#banner .banner_img img{ width: auto; max-height: 327px;}
#banner .owl-dots{ position: absolute; right: 0; bottom: 12px; margin-top: 0;}
#banner .owl-dots .owl-dot span{ width: 8px; height: 8px; background: #fff; margin: 0 5px; display: block; border-radius: 30px;}
#banner .owl-dots .owl-dot.active span{ outline: 1px solid #fff; outline-offset: 2px;}

.banner .slick-dots{ position: absolute; right: 15px; bottom: 15px; margin-top: 0; width: auto; display: flex !important;}
.banner .slick-dots li{ width: auto; height: auto; }
.banner .slick-dots li button{ width: 8px; height: 8px; background: #fff; display: block; border-radius: 30px; padding: 0;}
.banner .slick-dots li button::before{ display: none;}
.banner .slick-dots li.slick-active button{ outline: 1px solid #fff; outline-offset: 2px;}

.pad_t80{ padding-top: 70px;}
.pad_t30{ padding-top: 30px;}
.pad_b75{ padding-bottom: 75px;}

.button1{ min-width: 180px; line-height: 50px; background: #FEB41D; color: #111; text-decoration: none; border-radius: 30px; font-size: 20px; font-weight: 400; text-align: center; display: inline-block; border: 0; cursor: pointer; padding: 0 40px;}
.button1:hover{ background: #0B0E34; color: #fff;}
.button2{ min-width: 180px; line-height: 50px; background: #363B73; color: #fff; text-decoration: none; border-radius: 30px; font-size: 20px; font-weight: 400; text-align: center; display: inline-block; border: 0; cursor: pointer; padding: 0 40px;}
.button2:hover{ background: #FEB41D; color: #111;}
.button3{ min-width: 180px; line-height: 48px; background: #fff; color: #363B73; text-decoration: none; border-radius: 30px; font-size: 20px; font-weight: 400; text-align: center; display: inline-block; border: 1px solid #363B73 !important; cursor: pointer;}
.button3:hover{ background: #363B73; color: #fff;}
.button6{ min-width: 180px; line-height: 48px; background: #363B73; color: #fff; text-decoration: none; border-radius: 30px; font-size: 20px; font-weight: 400; text-align: center; display: inline-block; border: 1px solid #363B73 !important; cursor: pointer;}
.button6:hover{ background: #FEB41D; color: #fff;}
.button4{ min-width: 40px; line-height: 35px; background: #363B73; color: #fff; text-decoration: none; border-radius: 30px; font-size: 14px; font-weight: 400;   text-align: center; display: inline-block; border: 0; cursor: pointer; padding: 0 20px; margin-bottom: 10px;}
.button4:hover{ background: #FEB41D; color: #111;}
.button5{ min-width: 150px;  background: #363B73; color: #fff; text-decoration: none; border-radius: 30px; font-size: 18px; font-weight: 400; text-align: center; display: inline-block; border: 0; cursor: pointer; padding: 0 40px;}
.button5:hover{ background: #FEB41D; color: #111;}
.no-price { font-weight: bold;}

.top_category h2{ margin-bottom: 0;}
.top_category ul{ margin: 0; padding: 40px 0 0; list-style: none; display: flex; flex-wrap: wrap; justify-content: space-between;}
.top_category ul li a{ display: flex; flex-direction: column; align-items: center; text-decoration: none;}
.top_category ul li .tcImg{ background: #F4F5FF; padding: 10px 9px; border-radius: 10px; transition:all 0.25s ease-in 0s; -webkit-transition:all 0.25s ease-in 0s;}
.top_category ul li .tcImg img{ max-width: 73px; border-radius: 10px;}
.top_category ul li .tcName{ font-weight: 500; color: #111; margin-top: 18px; transition:all 0.25s ease-in 0s; -webkit-transition:all 0.25s ease-in 0s;}
.top_category ul li a:hover .tcImg{ background: #FEB41D;}
.top_category ul li a:hover .tcName{ color: #363B73;}

.featured_row{ display: flex; flex-wrap: wrap; gap: 20px;}
.featured_sidebar{ width: 24.2%; border: 1px solid #FEB41D; border-radius: 10px; padding: 22px 0 0 20px;}
.featured_sidebar ul{ margin: 0; padding: 0; list-style: none;}
.featured_sidebar ul li{ border-bottom: 1px solid #FFE9BC; padding-bottom: 8px; margin-bottom: 20px;}
.featured_sidebar ul li a{ display: flex; text-decoration: none;}
.featured_sidebar ul li .icon{ min-width: 70px;}
.featured_sidebar ul li h4{ font-size: 20px; font-weight: 500; color: #111; margin-bottom: 6px; transition:all 0.25s ease-in 0s; -webkit-transition:all 0.25s ease-in 0s;}
.featured_sidebar ul li:hover h4{ color: #FEB41D;}
.featured_sidebar ul li p{ color: #4C4C4C; margin-bottom: 0;}

.featured_bx{ width: calc(75.8% - 20px); display: flex; flex-wrap: wrap; gap: 20px;}
.pkbx{ width: calc(50% - 10px); position: relative; display: flex;}
.pkbx figure{ margin-bottom: 0; width: 100%;}
.pkbx figure img{ width: 100%; border-radius: 10px; height: 100%; object-fit: cover;}
.pkbx figcaption{ position: absolute; left: 0; bottom: 0; width: 100%; height: 100%; border-radius: 10px; padding: 18px 170px 18px 20px; display: flex; flex-wrap: wrap; flex-direction: column; justify-content: flex-end; background: linear-gradient(360deg, #0B0E30 -350%, rgba(54, 59, 115, 0) 94.8%);}
.pkbx figcaption h3{ font-size: 24px; font-weight: 500; color: #000; margin-bottom: 9px;}
.pkbx figcaption p{ font-size: 16px; font-weight: 500; color: #000; margin-bottom: 0;}
.pkbx figcaption .rnbtn{ position: absolute; right: 20px;}
.pkbx figcaption .rnbtn a{ font-size: 20px; font-weight: 500; color: #FEB41D; text-decoration: none;}
.pkbx figcaption .rnbtn img{ margin-left: 11px;}
.outofstock {color: #fdcd9a;}
.footer{ background: #0B0E34; padding-top: 70px;}
.footer_row{ display: flex; flex-wrap: wrap; justify-content: space-between; padding-bottom: 21px;}
.footer_bx{ font-size: 16px; font-weight: 500; color: #fff;}
.footer_bx h3{ font-size: 24px; font-weight: 500; color: #fff; margin-bottom: 21px;}
.footer_bx .footer_address p a{ color: #fff; text-decoration: none;}
.footer_bx .footer_address p a:hover{ color: #FEB41D;}
.footer_bx .footer_address p img{ margin-right: 7px;}
.footer_bx .footer_social_icons{ padding-top: 25px;}
.footer_bx .footer_social_icons a{ margin-right: 15px;}
.footer_bx ul{ margin: 0; padding: 0; list-style: none;}
.footer_bx ul li{ padding-bottom: 16px;}
.footer_bx ul li a{ color: #fff; text-decoration: none;}
.footer_bx ul li a:hover{ color: #FEB41D;}
.footer_bx .office_time{ display: flex; flex-wrap: wrap; flex-direction: column; align-items: flex-end;}
.footer_bx .day{ min-width: 34px;}
.footer_bx .daytime{ display: flex; flex-wrap: wrap; padding-bottom: 7px;}
.footer_bx .daytime .time{ min-width: 115px; text-align: right;}
.copyright{ border-top: 1px solid #AAAAAA; font-weight: 500; color: #fff; text-align: center; padding: 15px 0;}

/*Login page css start*/
.login_register{ padding: 70px 0; overflow: hidden;}
.login_register .container{ max-width: 1226px;}
.lrinner{ padding: 40px; background: #F4F5FF; border-radius: 10px;}
.lrinner h1{ font-size: 32px; margin-bottom: 26px;}
.lrinner p{ color: #4C4C4C; margin-bottom: 0;}
.login_img{ width: 46.5%;}
.login_img img{ width: 100%; border-radius: 10px;}
.login_form{ width: 53.5%; padding-left: 40px;}
.common_form .form_group{ margin-bottom: 20px;}
.common_form .form-control{ background-color: #fff; box-shadow: none; color: #4C4C4C; border: 1px solid #363B73; border-radius: 10px; padding: 14px; line-height: normal; background-size: 20px 16px; resize: none; transition: all 0.25s ease-in 0s; -webkit-transition: all 0.25s ease-in 0s;}
.common_form .form-control:focus{ border-color: #FEB41D;box-shadow:none;}
.common_form .form-control.form-select{ padding-right: 40px;}
.login_form .fplink a{ font-size: 16px; font-weight: 500; color: #363B73; text-decoration: underline;}
.login_form .fplink a:hover{ color: #FEB41D; text-decoration: none;}
.login_form .login_btn{ padding-top: 38px;}
.login_form .login_btn .button2{ display: block; width: 100%;}
.login_bottom_content h3{ font-size: 24px; margin-bottom: 14px;}
.login_bottom_content .form-check{ margin: 3px 0 0;}
.login_bottom_content .form-check .form-check-input{ background-color: transparent; border-color: #aaa; box-shadow: none;}
.login_bottom_content .form-check .form-check-input:checked{ background-color: #363B73; border-color: #363B73;}
.login_bottom_content .form-check .form-check-label{ color: #4C4C4C;}
.register_content{ font-size: 16px;}

/*Listing page css start*/
.breadcrumb{ margin-top: 15px;}
.breadcrumb .breadcrumb-item{ color: #111111;}
.breadcrumb .breadcrumb-item a{ color: #111111; text-decoration: none;}
.breadcrumb .breadcrumb-item a:hover{ color: #FEB41D;}
.breadcrumb .breadcrumb-item img{ margin-right: 2px;}
.breadcrumb .breadcrumb-item + .breadcrumb-item:before{ color: #111; content: var(--bs-breadcrumb-divider, ">")}
.breadcrumb .breadcrumb-item.active{color: var(--bs-breadcrumb-item-active-color)}

.clinner{ padding: 40px 0 70px;}
.clSidebar{ width: 23%; background: #F4F5FF; border-radius: 10px; border: 1px solid #363B73; overflow: hidden;}
.clSidebar ul{ margin: 0; padding: 0;list-style: none;}
.clSidebar ul li a{ font-size: 16px; font-weight: 500; color: #4C4C4C; text-decoration: none; padding: 12px 30px; display: block; position: relative;}
.clSidebar ul li a svg{ position: absolute; right: 25px; margin-top: 3px;}
.clSidebar ul li a:hover{ background: #fff;}
.clSidebar ul li a.active{ background: #363B73; color: #fff;}

.clproduct{ width: 77%; padding-left: 40px;}
.clproduct h1{ font-size: 32px; margin-bottom: 5px;}
.clproduct ul{ margin: 0; padding: 0; list-style: none; display: flex; flex-wrap: wrap; gap: 20px;}
.clproduct ul li{ width: calc(20% - 16px); margin-top: 20px;}
.clproduct ul li a{ padding: 8px; display: block; border: 1px solid #FFE9BC; border-radius: 10px; text-decoration: none;}
.clproduct ul li a:hover{ background: #F4F5FF;}
.clproduct ul li figure{ width: 100%; text-align: center; margin-bottom: 0;}
.clproduct ul li figure img{ width: 100%; border-radius: 10px;}
.clproduct ul li .categoryname_row{ display: flex; flex-wrap: wrap; justify-content: space-between; font-size: 16px; color: #363B73; padding: 7px 0;}

/*Product Listing page css start*/
.product_sidebar{ width: 23%;}
.product_sidebar .pstitle{ padding-bottom: 21px;}
.product_sidebar .pstitle h3{ font-size: 20px; margin-bottom: 0;}
.product_sidebar .pstitle a{ font-size: 16px; font-weight: 500; color: #363B73;}
.product_sidebar .pstitle a:hover{ text-decoration: none;}
.product_sidebar .accordion-item{ border: 1px solid #363B73; border-radius: 5px; background: #F4F5FF; margin-bottom: 14px; overflow: hidden;}
.product_sidebar .accordion-button{ padding: 10px 14px; font-size: 16px; font-weight: 500; color: #363B73; box-shadow: none; background: none;}
.product_sidebar .accordion-button:before{ content: ""; border-bottom: 2px solid #cfcfd4; position: absolute; left: 14px; right: 14px; bottom: -2px;}
.product_sidebar .accordion-button:after{ background: url(https://davenportimage.s3.amazonaws.com/plus-icon.svg); width: 14px; height: 14px;}
.product_sidebar .accordion-button:not(.collapsed):after{ background: url(https://davenportimage.s3.amazonaws.com/minus-icon.svg); height: 2px;}
.product_sidebar .accordion-body{ padding: 10px 14px;}
.productContent a {text-align: center;}

.custom_check.form-check{ margin: 3px 0 0;}
.custom_check.form-check .form-check-input{ background-color: transparent; border-color: #aaa; box-shadow: none; cursor: pointer;}
.custom_check.form-check .form-check-input:checked{ background-color: #363B73; border-color: #363B73;}
.custom_check.form-check .form-check-label{ color: #111; padding-left: 5px; cursor: pointer;}

.custom_radio.form-check .form-check-input{ width: 18px; height: 18px; border-color: #363B73;}
.custom_radio.form-check .form-check-input:checked{ background: #363B73; outline: 3px solid #f4f5ff; outline-offset: -5px;}

.prd_block{ width: 77%; padding-left: 40px;position: relative;}
.prd_toolbar{ display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; padding-bottom: 20px;}
.prd_toolbar h1{ font-size: 32px; margin-bottom: 0;}
.tbSorter{ display: flex; flex-wrap: wrap;}
.tbSorter .toolbar_sorter{ padding-left: 40px; display: flex; flex-wrap: wrap; align-items: center;}
.tbSorter .toolbar_sorter label{ font-size: 16px; padding-right: 5px;}
.tbSorter .toolbar_sorter select{ width: auto; border: 1px solid #363B73; background-color: #fff; border-radius: 5px; box-shadow: none;}
.tbSorter .toolbar_sorter .perpage{ font-size: 16px; padding-left: 5px;}

.prd_grid ul{ margin: 0; padding: 0; list-style: none; display: flex; flex-wrap: wrap; align-items: flex-start; gap: 20px;}
.prd_grid ul li{ width: calc(20% - 16px); margin-top: 20px; padding: 8px; border: 1px solid #FFE9BC; border-radius: 10px; transition:all 0.25s ease-in 0s; -webkit-transition:all 0.25s ease-in 0s;}
.prd_grid ul li:hover{ background: #F4F5FF;}
.prd_grid ul li a{ display: block; text-decoration: none;}
.prd_grid ul li figure{ width: 100%; text-align: center; margin-bottom: 10px;}
.prd_grid ul li figure img{ /*width: 100%;*/ border-radius: 10px;}
.prd_grid ul li .productThumb{ width: 100%; text-align: center; margin-bottom: 10px; min-height: 210px; cursor: pointer;}
.prd_grid ul li .instock_row{ display: flex; align-items: center; justify-content: space-between;}
.prd_grid ul li .prd_rating{ display: flex; align-items: center; padding: 8px 0;display: none;}
.prd_grid ul li .prd_rating img{ margin-right: 1px; cursor: pointer;}
.prd_grid ul li .prd_price{ font-size: 16px; color: #363B73; position: relative;}
.prd_grid ul li .prd_price .old_price{ text-decoration: line-through;}
.prd_grid ul li h4{ font-size: 16px; font-weight: 600; color: #01000D; margin: 8px 0;}
.prd_grid ul li p{ font-size: 16px; margin: 5px 0;}
.prd_grid ul li .add_qty{ padding: 20px 0 10px; display: flex; align-items: center; justify-content: space-between;}
.prd_grid ul li .qty_plusminus{ display: flex; align-items: center; border: 1px solid #363B73; border-radius: 5px; padding: 9px 7px; flex: 1; margin-right: 10px;}
.prd_grid ul li .qty_plusminus button{ border: 0; background: none; width: 20px; padding: 0; line-height: normal;}
.prd_grid ul li .qty_plusminus input{ width: 40px; border: 0; padding: 0; background: none; font-size: 16px; text-align: center; flex: 1;}
.prd_grid ul li .add_btn .button2{ min-width: 80px; padding: 0; line-height: 44px;}
.prd_grid ul li .view_variations_btn{ padding: 18px 0 10px;}
.prd_grid ul li .view_variations_btn .button2{ padding: 0;}
.prd_grid ul li .priceBadge{ background: #FEB41D; color: #000; font-size: 9px; text-align: center; width: 50px; height: 50px; border-radius: 50px; display: flex;   justify-content: center; align-items: center; padding: 0; font-weight: 400; position: absolute; right: 0; top: 5px; line-height: normal; z-index: 1;}

.fav_qty .qty_plusminus{display: flex; align-items: center; border: 1px solid #363B73; border-radius: 5px; padding: 9px 14px;}
.fav_qty .qty_plusminus input{ width: 40px; border: 0; padding: 0; background: none; font-size: 16px; text-align: center;}
.fav_qty .qty_plusminus button{ border: 0; background: none; width: 20px; padding: 0; line-height: normal;}

.more_qty{ padding: 2px 0 9px;}
.more_qty .more_qty_row{ display: flex; align-items: center; justify-content: space-between; padding-top: 5px;}
.more_qty .more_qty_row label{ font-weight: 500; color: #4C4C4C;}
.more_qty .more_qty_row label.discount_label{ font-size: 16px;}
.more_qty .more_qty_row span{ color: #4C4C4C;}

.pagination_box .pagination{ padding-top: 30px; margin: 0; justify-content: center;}
.pagination_box .pagination .page-item .page-link{ font-weight: 700; border-color: #363B73; padding: 8px 15px; color: #363B73;}
.pagination_box .pagination .page-item.disabled .page-link{ background-color: #F4F5FF;}
.pagination_box .pagination .page-item.active .page-link{ background-color: #363B73; color: #fff;}
.productListLoader{position:absolute;left:0;right:0;top:0;bottom: 0;margin: auto;}


/*Checkout page css start*/
.checkout_wrap h1{ font-size: 32px; margin-bottom: 36px;}
.checkout_cart{ width: 50%; padding-right: 20px;}
.cart_tbl .table{ margin-bottom: 0;}
.cart_tbl .table thead tr th{ border: 0; border-bottom: 1px solid #FFE9BC; padding: 0 0 6px; font-size: 20px; font-weight: 500; color: #111;}
.cart_tbl .table thead tr th.product_quantity{ min-width: 130px;}
.cart_tbl .table thead tr th.product_remove{ min-width: 51px;}
.cart_tbl .table tbody tr td{ border-bottom-color: #FFE9BC; font-size: 16px; color: #4C4C4C; padding: 25px 10px;}
.cart_tbl .table tbody tr td.product_thumbnail img{ max-width: 120px;}
.cart_tbl .table tbody tr td.product_name{ padding-left: 20px; padding-right: 20px;}
.cart_tbl .table tbody tr td.product_name h4{ font-size: 20px; font-weight: 600; margin-bottom: 10px;}
.cart_tbl .table tbody tr td.product_name h4 a{ color: #111; text-decoration: none;}
.cart_tbl .table tbody tr td.product_name p{ margin-bottom: 2px;}
.cart_tbl .table tbody tr td.product_name .variant{ font-size: 16px; padding-top: 9px;}
.cart_tbl .table tbody tr td.product_name .variant span{ font-weight: 600;}
.checkout_cart .gotocart_btn{ text-align: right; padding-top: 40px;}
.cart_loader{position: absolute;left: 0;right: 0;top: 0;bottom: 0;}

.checkout_right{ width: 50%; padding-left: 20px; position: relative;}
.checkout_right h3{ font-size: 24px; margin-bottom: 23px;}
.checkout_right .addnewaddress{ position: absolute; right: 0; top: -2px;}
.checkout_right .addnewaddress a{ font-size: 20px; color: #363B73; font-weight: 500;}
.checkout_right .addnewaddress a:hover{ text-decoration: none;}
.delivery_options{ padding-top: 20px;}
.delivery_options ul{ margin: 0; padding: 0; list-style: none; display: flex; flex-wrap: wrap; column-gap: 20px;}
.delivery_options ul li{ width: calc(50% - 10px); margin-bottom: 20px;}
.delivery_options ul li .form-check{ margin: 0; padding: 0; position: relative;}
.delivery_options ul li .form-check .form-check-input{ margin: 0; position: absolute; left: 0; right: 0; top: 0; bottom: 0; opacity: 0;width: 100%; height: 50px;}
.delivery_options ul li .form-check .form-check-label{ width: 100%; background: #fff; font-size: 16px; color: #4C4C4C; border: 1px solid #363B73; border-radius: 10px; padding: 14px; line-height: normal; display: flex; align-items: center; justify-content: space-between; cursor: pointer; transition: all 0.25s ease-in 0s; -webkit-transition: all 0.25s ease-in 0s;}
.delivery_options ul li .form-check .form-check-input:checked + .form-check-label{ background: #363B73; color: #fff;}
.delivery_options ul li .form-check .form-check-label b{ font-weight: 400;}

.summary_bx{ border: 1px solid #FEB41D; border-radius: 10px; padding: 20px; margin-top: 20px;}
.summary_bx ul{ margin: 0; padding: 0; list-style: none;}
.summary_bx ul li{ border-bottom: 1px solid #FFE9BC; display: flex; align-items: center; justify-content: space-between; font-size: 16px; color: #4C4C4C; margin-bottom: 21px; padding-bottom: 8px;}
.summary_bx ul li label{ font-weight: 500; color: #111;}
.summary_bx ul li input{ border: 1px solid #363B73; border-radius: 10px; padding: 10px; line-height: normal; max-width: 170px;}
.summary_bx .btn_row{ display: flex; flex-wrap: wrap; column-gap: 20px;}
.summary_bx .btn_row a{ width: calc(50% - 10px); padding: 0;}

.changeAddressModal{ padding-left: 0;}
.braintree-sheet__content--form .braintree-form__notice-of-collection{ width: 100%; font-size: 14px; padding-left: 5px; text-align: left; font-weight: 600;}
.braintree-sheet__content--form .braintree-form__notice-of-collection a{ color: #363B73; text-decoration: none;}

/*Contact us page css start*/
.common_sidebar.clSidebar{ background: none;}
.common_sidebar.clSidebar ul li a:hover{ background: #F4F5FF;}
.common_sidebar.clSidebar ul li a.active{ background: #363B73;}
.common_sidebar.clSidebar ul li a svg{ right: 15px; margin-top: 6px;}
.common_sidebar.clSidebar ul li ul li a{ font-weight: 400; padding-left: 48px;}
.common_sidebar.clSidebar ul li ul li a.active{ background: #F4F5FF; color: #4C4C4C;}
.common_right_block{ width: 77%; padding-left: 40px;}
.contact_details{ background: #F4F5FF; padding: 40px; border-radius: 10px;}
.contact_details h1{ font-size: 32px;}
.contact_details p{ font-size: 16px; color: #4C4C4C;}
.contact_details .cdleft{ width: 31%; padding-top: 16px;}
.contact_details .cdleft h4{ font-size: 20px; margin-bottom: 24px;}
.contact_details .cdleft p{ margin-bottom: 11px;}
.contact_details .cdleft p strong{ color: #111; font-weight: 500;}
.cdCall{ padding-top: 10px;}
.cdCall a{ display: flex; align-items: center; text-decoration: none;}
.cdCall a .icon{ min-width: 30px; height: 30px; background: #363B73; border-radius: 30px; display: flex; align-items: center; justify-content: center; transition:all 0.25s ease-in 0s; -webkit-transition:all 0.25s ease-in 0s;}
.cdCall a .text{ color: #4C4C4C; font-size: 16px; padding-left: 10px;}
.cdCall a .text span{ font-weight: 500;}
.cdCall a:hover .icon{ background: #FEB41D;}
.contact_details .cdright{ width: 69%; display: flex; flex-wrap: wrap; gap: 20px;}
.contact_details .sales_team{ width: calc(50% - 10px); border: 1px solid #aaa; border-radius: 10px; padding: 20px;}
.contact_details .sales_team h4{ font-size: 20px; margin-bottom: 12px;}

/*Cart page css start*/
.cart_left{ width: 73%;}
.cart_left .cart_tbl .table tr .product_description, .cart_left .cart_tbl .table tr .product_price, .cart_left .cart_tbl .table tr .product_quantity, .cart_left .cart_tbl .table tr .product_subtotal, .cart_left .cart_tbl .table tr .product_remove, .cart_left .cart_tbl .table tr .product_stock{ padding-left: 35px;}
.cart_tbl .table tbody tr td.product_name .favorite_icon{ float: right;}
.cart_tbl .cart_qty{ display: flex; align-items: center; border: 1px solid #363B73; border-radius: 5px; padding: 0px 0 4px; width: 130px; margin: auto;}
.cart_tbl .cart_qty .qty_plusminus{ display: flex; flex: 1;}
.cart_tbl .cart_qty button{ border: 0; background: none; width: 29px; padding: 0; line-height: normal;}
.cart_tbl .cart_qty input{ width: 20px; border: 0; padding: 4px 0 1px; background: none; font-size: 16px; text-align: center; line-height: normal; flex: 1;}
.cart_summary{ width: 27%; padding-left: 40px;}
.cart_summary .summary_bx{ margin-top: 0;}
.cart_summary .summary_bx h3{ font-size: 24px; margin-bottom: 23px;}
.cart_summary .summary_bx .btn_row .button2{ width: 100%; padding: 0;}

.cart_recent_items{ width: 100%; padding-top: 60px;}
.cart_recent_items h2{ font-size: 32px; margin-bottom: 20px;}
.cart_recent_items .prd_grid ul li{ width: calc(16.66% - 17px);}

/*Account Balance / Pay Bill page css start*/
.aged_details{ padding-top: 24px;}
.aged_details h4{ margin-bottom: 15px;}
.aged_details ul{ margin: 0; padding: 8px 0 0; list-style: none; display: flex; flex-wrap: wrap; gap: 10px;}
.aged_details ul li{ width: calc(16.66% - 9px); background: #363B73; border-radius: 5px; font-size: 16px; color: #fff; height: 84px; display: flex; align-items: center; justify-content: center; text-align: center; line-height: 2.4;}
.aged_details ul li:nth-child(1), .aged_details ul li:nth-child(2), .aged_details ul li:nth-child(3){ color: #FEB41D;}
.aged_details .oda{ font-size: 16px; font-weight: 500; color: #FEB41D; margin: 22px 0 17px;}
.aged_details .form-check{ display: flex; flex-wrap: wrap; align-items: center; margin: 0 0 13px;}
.aged_details .form-check .form-check-label{ font-size: 16px; padding-left: 15px; color: #4C4C4C; min-width: 212px; margin-right: 15px;}
.aged_details .form-check .form-control{ width: 115px; border: 1px solid #363B73; border-radius: 0; box-shadow: none; background: no-repeat; padding: 2px 5px; line-height: normal; font-size: 13px;}
.aged_details .form-check a{ font-size: 16px; font-weight: 500; color: #363B73; text-decoration: underline; margin-left: 15px;}
.aged_details .pleasenote{ font-size: 16px; font-weight: 500; color: #4C4C4C; padding-top: 8px;}

.outstanding_invoices{ padding-top: 44px;}
.outstanding_invoices h3{ font-size: 24px; margin-bottom: 30px;}
.common_table table{ width: 100%;}
.common_table table thead tr th{ font-size: 16px; font-weight: 500; padding: 0 0 19px 20px; border-bottom: 1px solid #aaa; line-height: normal; min-width: 120px;}
.common_table table thead tr th:first-child{ padding-left: 0;}
.common_table table tbody tr td{ font-size: 16px; padding: 22px 0 9px 20px; border-bottom: 1px solid #aaa; color: #4C4C4C;}
.common_table table tbody tr td:first-child{ padding-left: 0;}

/*Order History and Favourite Products page css start*/
.ohFilter{ position: absolute; right: 40px; top: 40px; display: flex;}
.ohFilter .form-control{ background-color: transparent; border-radius: 5px; font-size: 14px; color: #111; padding: 10px; min-width: 155px;}
.ohFilter .order_status{ padding-left: 20px;}
.common_table.fpTbl table tr .checkbox, .common_table.fpTbl table tr .remove{ min-width: inherit;}
.common_table.fpTbl table tr .checkbox .form-check{ margin: 0; display: inherit;}

/*Static pages css start*/
.common_content h1{ font-size: 32px; margin-bottom: 20px;}
.common_content h3{ font-size: 24px; margin-top: 24px;}
.common_content h4{ font-size: 20px; margin-top: 24px;}
.common_content p{ font-size: 16px; color: #4C4C4C; line-height: 1.7; text-align: justify; margin-bottom: 24px;}
.common_content p:last-of-type{ margin-bottom: 0;}
.common_content p a{ color: #FEB41D;}
.common_content p a:hover{ text-decoration: none;}
.common_content p strong{ font-weight: 600;}
.common_content ul{ margin: 24px 0 0;}
.common_content ul li{ font-size: 16px; color: #4C4C4C; line-height: 1.7;}
.common_content ul li a{ color: #FEB41D;}
.common_content ul li a:hover{ text-decoration: none;}

/*Latest news pages css start*/
.news_article_list ul{ margin: 0; padding: 0; list-style: none;}
.news_article_list ul li{ display: flex; flex-wrap: wrap; align-items: center; border-bottom: 1px solid #FFE9BC; padding: 25px 0;}
.news_article_list ul li:first-child{ padding-top: 0;}
.news_article_list ul li .nal_img{ width: 20%;}
.news_article_list ul li .nal_img img{ border-radius: 10px;}
.news_article_list ul li .nal_content{ width: 80%; padding-left: 40px;}
.news_article_list ul li .nal_content h3{ font-size: 24px; font-weight: 600; margin-bottom: 10px;}
.news_article_list ul li .nal_content p{ font-size: 16px; color: #4C4C4C; margin-bottom: 30px;}

/*Product detail page css start*/
.pdLeft{ width: 50%; padding-right: 20px;}
.pdRight{ width: 50%; padding-left: 20px;}
.pdRight h1{ font-size: 32px; margin-bottom: 0;}
.pdRight .instock_row{ display: flex; align-items: center; justify-content: space-between; padding: 18px 0 8px;}
.pdRight .instock_row .instock{ font-size: 16px;}
.pdRight .instock_row .instock img{ width: 24px; margin-right: 7px;}
.pdRight .instock_row .wishlist img{ width: 34px;}
.pdRight .price_rating{ display: flex; align-items: center; justify-content: space-between;}
.pdRight .price_rating .price{ font-size: 36px; color: #363B73; font-weight: 500;}
.pdRight .prd_rating{ display: flex; align-items: center;display: none;}
.pdRight .prd_rating img{ width: 38px; margin-left: 2px; cursor: pointer;}
.pdRight .prd_details{ padding-top: 18px;}
.pdRight .prd_details h4{ font-size: 20px; margin-bottom: 15px;}
.pdRight .prd_details ul{ margin: 0; padding: 0; list-style: none;}
.pdRight .prd_details ul li{ display: flex; flex-wrap: wrap; justify-content: space-between; border-bottom: 2px solid #d4d4d4; padding: 13px 0; font-size: 16px; color: #4C4C4C;}
.pdRight .prd_details ul li:first-child{ border-top: 2px solid #d4d4d4;}

.pdRight .add_qty{ padding-top: 40px; display: flex; align-items: center;}
.pdRight .qty_plusminus{ display: flex; align-items: center; border: 1px solid #363B73; border-radius: 5px; padding: 10px 14px; margin-right: 40px; min-width: 160px;}
.pdRight .qty_plusminus button{ border: 0; background: none; width: 20px; padding: 0; line-height: normal;}
.pdRight .qty_plusminus input{ width: 40px; border: 0; padding: 0; background: none; font-size: 16px; text-align: center; flex: 1;}
.pdRight .add_btn .button2{ line-height: 46px;}

.pdRight .more_qty{ padding: 0;}
.pdRight .more_qty .more_qty_row{ border-bottom: 2px solid #d4d4d4; padding: 13px 0; font-size: 16px;}

.pdTbs .nav-tabs{ border: 1px solid #aaa; display: inline-flex; padding: 4px; border-radius: 30px; margin: 0 0 20px;}
.pdTbs .nav-tabs li .nav-link{ font-size: 16px; font-weight: 500; color: #111; margin: 0; border: 0; padding: 8px 25px; background: none; border-radius: 30px;}
.pdTbs .nav-tabs li .nav-link.active{ background: #363B73; color: #fff;}

.gpd_img{ border: 1px solid #FFE9BC; border-radius: 10px; text-align: center; padding: 50px 0;}
.pdRight .prd_details.gdBdr ul li{ border-bottom: 0;}
.gpd_cart{ padding-top: 60px;}
.gpd_cart .cart_left{ width: 100%;}
.cart_tbl .table tbody tr td.product_stock img{ width: 24px; margin-right: 5px;}

/* Slick slider with thumbnail css */
.slider-container{ overflow: hidden;}
.slider .slick-prev:before, .slider-vertical .slick-next:before{ display: none;}
.slider .slick-prev, .slider .slick-next{ width: 28px; height: 28px; border-style: solid; margin: 10px; display: inline-block; margin: auto; left: 0; right: 0; top: 0; bottom: 0; z-index: 10; cursor: pointer;}
.slider .slick-prev:before, .slider .slick-next:before{ display: none;}
.slider .slick-prev{ border-width: 14px 14px 14px 0px; border-color: transparent #363B73; left: 0px; right: auto;}
.slider .slick-next{ border-width: 14px 0px 14px 14px; border-color: transparent #363B73; right: 0px; left: auto;}	
.slider .slick-prev:hover, .slider .slick-next:hover{ border-color: transparent #ffd200;}
.slider .slick-prev.slick-disabled, .slider .slick-next.slick-disabled, .slider .slick-prev.slick-disabled:hover, .slider .slick-next.slick-disabled:hover{ opacity: 0.1; cursor: default; border-color: transparent #000000;}
.slider div{ text-align: center; font-size: 75px; color: #ffffff; vertical-align: middle;}
.slider div .slick-slider-item img{ max-height: 500px; margin: auto; object-fit: contain;}
.slider-content .slick-slide{border-radius: 15px;border: solid 1px #FEB41D;overflow: hidden;}
.slider-thumb{ margin-top:35px;}
.slider-thumb div{ line-height: 25px; font-size: 25px;}
.slider-thumb .slick-track{display: flex; justify-content: left;}
.slider-thumb div img{border-radius: 15px; max-width: 100%;}
.slider-thumb .slick-slide{border: solid 1px #febe39; max-width: 120px; border-radius: 15px;margin-right: 20px;}
.slider-thumb .slick-slide.slick-current{ border: solid 1px #363B73; border-radius: 15px;}

.imageZoomPreview{ position: absolute; top: 0; right: -470px; width: 500px; height: 502px; background-repeat: no-repeat; border: 4px solid #363B73;}

/*Account Application page css start*/
.account_application h4{ font-size: 20px;}
.account_application .custom_check{ margin: 12px 0 0;}
.account_application .custom_check .form-check-label{ font-size: 16px; color: #4C4C4C;}
.account_application .minheight{ min-height: 80px;}
.account_application .custom_radio{ margin-right: 40px; padding-bottom: 10px;}

/*Loader css start*/
.loader{ border: 5px solid #f3f3f3; border-top: 5px solid #373b71; border-radius: 50%; width: 50px; height: 50px; animation: spin 2s linear infinite; margin: 0 auto; margin-top: 10%;}
.loaderOuter{ position: fixed; left: 0; right: 0; top: 0; bottom: 0; background: rgba(0, 0, 0, 0.7); z-index: 1000; display: flex; align-items: center; justify-content: center;}
.loaderOuter .loader{ margin: 0;}
.NotFound{ display: flex; flex-direction: column; justify-content: center; align-items: center; min-height: calc(100vh - 145px);}
.NotFound__image{ max-width: 35vw;}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.notFound_record{ width: 100%; text-align: center; padding-top: 15vh;}
/*Loader css end*/

/*Quick Order page css start*/
.quick_cart_tbl{ min-height: 100px;}
.quick_cart_tbl .addPrdTitle{ font-size: 24px; font-weight: 500; display: none;}
.quick_cart_tbl .QuickSearchItem{ position: absolute; width: 11.5vw;}
.quick_cart_tbl .cart_tbl .table tbody tr td.product_thumbnail{ min-width: 11vw;}
.quick_cart_tbl .quick_button{ padding-top: 20px;}
.quick_cart_tbl .quick_cart_prd_notFound{ font-weight: 500;}
.quick_cart_tbl .quick_cart_prd_notFound a{ color: #363B73;}
.productNameStyle{cursor: pointer; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}





/*======================================================================
    Responsive css start
=======================================================================*/
@media only screen and (max-width: 1600px) {

    .prd_grid ul li{ width: calc(25% - 15px);}
    .common_table table thead tr th{ min-width: 130px;}
    .menu_wrap .navbar .navbar-nav > li > a{ padding: 0 10px;}
}
@media only screen and (max-width: 1400px) {

  .menu_wrap .navbar .navbar-nav > li > a{ padding: 0 5px;}
  .clproduct{ padding-left: 20px;}
    .clproduct ul li{ width: calc(25% - 15px);}
    .clSidebar ul li a{ padding: 12px 15px;}
    .clSidebar ul li a svg{ right: 15px;}
    .common_sidebar.clSidebar ul li ul li a{ padding-left: 25px;}
    .prd_block{ padding-left: 20px;}
    .cart_tbl .table tbody tr td.product_name{ padding-left: 10px; padding-right: 10px;}
    .cart_recent_items .prd_grid ul li{ width: calc(20% - 16px);}
    .pdRight .price_rating .price{ font-size: 32px;}
}
@media only screen and (max-width: 1280px) {

    .menu_wrap .navbar .navbar-nav > li > a{ padding: 0;}
    .prd_grid ul li{ width: calc(33.33% - 14px);}
    .checkout_cart{ padding-right: 0;}
    .contact_details{ padding: 30px;}
    .aged_details ul li{ font-size: 14px;}
    .ohFilter{ right: 30px; top: 30px;}
    .slider-thumb .slick-track{display: flex;gap:10px;}
    .slider-thumb{margin-top:20px}
}
@media only screen and (max-width: 1199px) {

    .header_right > ul > li{ padding-left: 15px;}
    .header_right > ul > li > a .text{ display: none;}
    .searchingform input{ width: 35vw;}
    .menu_wrap{ display: none;}
    .menu_wrap .container{ padding: 0;}
    
    .navbar_toggler{ display: block;}
    .navbar_toggler .m-menu__toggle a{ width: 40px; height: 40px; position: relative;}
    .navbar_toggler .m-menu__toggle a span{ background: #0B0E34; display: block; height: 3px; width: 100%; border-radius: 2px; position: absolute; left: 0; -webkit-transition: all 300ms ease-in-out; transition: all 300ms ease-in-out;}
    .navbar_toggler .m-menu__toggle a span:first-child { top: 8px;}
    .navbar_toggler .m-menu__toggle a span:nth-child(2) { top: 18px;}
    .navbar_toggler .m-menu__toggle a span:last-child { top: 28px;}
    .mm-ocd-opened .navbar_toggler span:nth-child(2){ opacity: 0;}
    .mm-ocd-opened .navbar_toggler span:first-child{ -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg); position: absolute; top: 18px;}
    .mm-ocd-opened .navbar_toggler span:last-child{ -webkit-transform: rotate(-45deg); -ms-transform: rotate(-45deg); transform: rotate(-45deg); position: absolute; top: 18px;}
    .navbar-collapse { z-index: 100; width: 100%; margin: 0;}
    .menu_wrap .navbar .navbar-nav > li{ border-top: 1px solid rgba(0,0,0,0.10); padding: 0; text-align: center;}
    
    #banner .item{ min-height: 300px;}
    .banner_content h2{ font-size: 40px;}
    .top_category ul{ padding-top: 0;}
    .top_category ul li{ width: 16.66%; padding-top: 40px;}
    .featured_sidebar{ width: 100%; order: 2;}
    .featured_bx{ width: 100%;}
    .footer_row{ justify-content: flex-start; padding-bottom: 0;}
    .footer_bx{ width: 33.33%; padding-bottom: 40px;}
    .footer_bx .office_time{ align-items: flex-start;}
    .footer_bx .daytime .day{ min-width: 35px;}
    
    .login_register{ padding: 40px 0 50px;}
    .breadcrumb{ margin: 0;}
    .clSidebar{ width: 27%;}
    .clproduct{ width: 73%;}
    .clproduct ul li{ width: calc(33.33% - 14px);}
    .prd_toolbar h1{ font-size: 30px;}
    .tbSorter .toolbar_sorter{ padding-left: 15px;}
    .checkout_cart{ width: 100%;}
    .checkout_cart .gotocart_btn{ padding-top: 25px;}
    .checkout_right{ width: 100%; padding: 40px 0 0;}
    .checkout_right .addnewaddress{ top: 38px;}
    .common_right_block{ width: 73%; padding-left: 20px;}
    .contact_details{ padding: 25px;}
    .contact_details .cdleft{ width: 100%;}
    .contact_details .cdright{ width: 100%; padding-top: 30px;}
    .contact_details .sales_team{ width: 100%;}
    .cart_left{ width: 100%;}
    .cart_left .cart_tbl .table tr .product_description, .cart_left .cart_tbl .table tr .product_price, .cart_left .cart_tbl .table tr .product_quantity, .cart_left .cart_tbl .table tr .product_subtotal, .cart_left .cart_tbl .table tr .product_remove, .cart_left .cart_tbl .table tr .product_stock{ padding-left: 20px;}
    .cart_summary{ width: 100%; padding: 40px 0 0;}
    .cart_recent_items .prd_grid ul li{ width: calc(25% - 15px);}
    .aged_details ul li{ width: calc(33.33% - 7px); font-size: 16px;}
    .ohFilter{ right: 25px; top: 25px;}
    .news_article_list ul li{ align-items: flex-start;}
    .news_article_list ul li .nal_content{ padding-left: 20px;}
    .news_article_list ul li .nal_content h3{ font-size: 20px;}
    .news_article_list ul li .nal_content p{ margin-bottom: 20px;}
    
    .pdLeft{ padding-right: 10px;}
    .pdRight{ padding-left: 10px;}
    .pdRight h1{ font-size: 30px;}
    .pdRight .price_rating{ flex-direction: column; align-items: flex-start; gap: 0.5rem;}
    .pdRight .price_rating .price{ font-size: 30px;}
    .pdRight .prd_rating img{ margin: 0 2px 0 0;}

    .quick_cart_tbl .QuickSearchItem{ width: 16vw;}
    .quick_cart_tbl .cart_tbl .table tbody tr td.product_thumbnail{ min-width: 15vw;}
    .imageZoomPreview{ display: none !important;}
    
}
@media only screen and (max-width: 991px) {
    
    .top_bar ul{ height: 36px; position: relative;}
    .top_bar ul li{ width: 100%; position: absolute; text-align: center; opacity: 0; animation-name: textFader; animation-duration: 18s; animation-timing-function: ease-in-out; animation-iteration-count: infinite;}
    .top_bar ul li:first-of-type{ animation-delay: 0s;}
    .top_bar ul li:nth-of-type(2){ animation-delay: 6s;}
    .top_bar ul li:last-of-type{ animation-delay: 12s;}
    .logo img{ max-width: 280px;}
    .searchingform input{ width: 28vw; padding: 11px;}
    .afterLogin_menu{ font-size: 12px; padding-bottom: 20px;}
    .afterLogin_menu a{ padding: 0 5px;}
    #banner .item{ min-height: 250px;}
    .banner_content h4{ font-size: 18px; margin-bottom: 10px;}
    .banner_content h2{ font-size: 30px; margin-bottom: 30px;}
    .banner_img{ width: 40%;}
    #banner .owl-dots{ left: 0; bottom: 10px;}
    .banner .slick-dots{ left: 0; right: 0; justify-content: center;}
    .pad_t80{ padding-top: 50px;}
    .pad_b75{ padding-bottom: 50px;}
    .pkbx{ width: 100%;}
    .footer{ padding-top: 50px;}
    
    .lrinner{ padding: 25px;}
    .login_form{ padding-left: 20px;}
    .clinner{ padding: 20px 0 50px;}
    .clSidebar{ width: 100%;}
    .clproduct{ width: 100%; padding: 30px 0 0;}
    .clproduct h1{ font-size: 30px; margin-bottom: 20px;}
    .clproduct ul li{ margin-top: 0;}
    .product_sidebar{ width: 100%;}
    .prd_block{ width: 100%; padding: 20px 0 0;}
    .prd_toolbar h1{ width: 100%; margin-bottom: 20px;}
    .tbSorter{ width: 100%; justify-content: space-between; padding-bottom: 10px;}
    .tbSorter .toolbar_sorter{ padding-left: 0;}
    .prd_grid ul li{ margin-top: 0;}
    .checkout_wrap h1{ font-size: 30px;}
    .cart_tbl .table thead tr th{ font-size: 18px;}
    .cart_tbl .table tbody tr td.product_name h4{ font-size: 18px;}
    .common_right_block{ width: 100%; padding: 40px 0 0;}
    .contact_details h1{ font-size: 30px;}
    .cart_left .cart_tbl .table tr .product_description, .cart_left .cart_tbl .table tr .product_price, .cart_left .cart_tbl .table tr .product_quantity, .cart_left .cart_tbl .table tr .product_subtotal, .cart_left .cart_tbl .table tr .product_remove, .cart_left .cart_tbl .table tr .product_stock{ padding-left: 10px; min-width: inherit;}
    .cart_left .cart_tbl .table tr .product_remove{ min-width: 22px;padding: 25px 5px;}
    .cart_recent_items{ padding-top: 40px;}
    .cart_recent_items h2{ font-size: 30px;}
    .cart_recent_items .prd_grid ul li{ width: calc(33.33% - 14px);}
    .outstanding_invoices{ padding-top: 35px;}
    .outstanding_invoices h3{ font-size: 22px;}
    .register_content p{ margin: -10px 0 30px;}
    .common_content h1{ font-size: 30px;}
    .common_content ul{ padding-left: 15px;}
    
    .pdLeft{ padding-right: 0; width: 100%;}
    .pdRight{ padding: 30px 0 0; width: 100%;}
    .account_application .minheight{ min-height: inherit;}
    .gpd_cart{ padding-top: 30px;}
    .gpd_img{ padding: 20px 0;}

    .quick_cart_tbl .QuickSearchItem{ width: 18vw;}
    .quick_cart_tbl .cart_tbl .table tbody tr td.product_thumbnail{ min-width: 20vw;}
    
}
@media only screen and (max-width: 767px) {
    
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container{ padding-left: 10px; padding-right: 10px;}
    .top_bar ul li{ line-height: normal;}
    .header_inner{ padding: 10px 0 62px; position: relative;}
    .afterLogin_menu{ display: none;}
    .afterLogin_menu.mobile{ display: flex; justify-content: center; text-align: center; width: 100%; border-bottom: 1px solid #F4F5FF; padding-bottom: 10px; margin-bottom: 10px;}
    .logo img{ max-width: 250px;}
    .header_right > ul > li{ padding-left: 10px;}
    .header_right > ul > li.navbar_toggler{ padding-left: 15px;}
    .header_right > ul > li:first-child{ padding-left: 0;}
    .header_right > ul > li.search_form{ position: absolute; left: 0; right: 0; bottom: 10px; display: flex; justify-content: flex-end;}
    .searchingform input{ width: 95vw;}
    .navbar-toggler{ right: 10px; top: -65px;}
    .banner{ padding: 0;}
    .bannerBgImg{ padding: 50px 0;}
    #banner .item{ min-height: inherit; aspect-ratio: inherit;}
    .banner_content{ width: 100%;}
    .banner_img{ width: 100%; padding-top: 30px; display: flex; justify-content: center;}
    #banner .owl-dots{ bottom: -30px;}
    /* .banner .slick-dots{ bottom: -27px;} */
    .top_category h2{ text-align: center;}
    .top_category ul{ justify-content: center;}
    .top_category ul li{ width: 25%;}
    .pkbx figcaption{ padding: 10px;}
    .pkbx figcaption h3{ font-size: 20px; margin-bottom: 5px;}
    .pkbx figcaption p{ font-size: 14px;}
    .pkbx figcaption .rnbtn a{ font-size: 16px;}
    .pkbx figcaption .rnbtn img{ margin-left: 7px; max-width: 18px;}
    .pkbx figcaption .rnbtn{ position: static; padding-top: 9px;}
    .footer_bx{ width: 100%;}
    .footer_bx ul li:last-of-type{ padding-bottom: 0;}
    
    .login_register{ padding: 0;}
    .login_register .container{ padding: 0;}
    .lrinner{ border-radius: 0; padding: 30px 10px 50px;}
    .login_img{ width: 100%; order: 2; padding-top: 35px;}
    .login_img img{ aspect-ratio: 1/0.9; object-fit: cover;}
    .login_form{ width: 100%; padding-left: 0;}
    .lrinner h1{ font-size: 30px;}
    .login_bottom_content h3{ font-size: 20px;}
    .login_form .login_btn{ padding-top: 30px;}
    .clproduct ul li{ width: calc(50% - 10px);}
    .prd_grid ul li{ width: calc(50% - 10px);}
    
    .checkout_wrap h1{ margin-bottom: 20px;}
    .cart_tbl .table thead{ display: none;}
    .cart_tbl .table tbody{ display: block;}
    .cart_tbl .table tbody tr{ display: flex; flex-wrap: wrap; margin-bottom: 25px;}
    .cart_tbl .table tbody tr.invalid-product{ margin-bottom: 0;}
    .cart_tbl .table tbody tr td{ width: 100%; display: block; padding: 15px 0 10px; text-align: right !important;}
    .cart_tbl .table tbody tr td.product_thumbnail{ width: 100px;}
    .cart_tbl .table tbody tr td.product_thumbnail img{ max-width: 100%;}
    .cart_tbl .table tbody tr td.product_name{ width: calc(100% - 100px); padding-right: 0; text-align: left !important;}
    .cart_tbl .table tbody tr td.product_name h4{ font-size: 16px; margin-bottom: 7px;}
    .cart_tbl .table tbody tr td.product_name p{ font-size: 14px; margin-bottom: 0;}
    .cart_tbl .table tbody tr td.product_name .variant{ font-size: 14px; padding-top: 5px;}
    .cart_tbl .table tbody tr td:before{ content: attr(data-title) "  "; font-weight: 500; float: left; color: #111;}
    .checkout_cart .gotocart_btn{ padding-top: 0;}
    .checkout_right h3{ font-size: 20px; margin-bottom: 18px;}
    .checkout_right .addnewaddress a{ font-size: 16px;}
    .delivery_options ul li{ width: 100%; margin-bottom: 12px;}
    .cart_left .cart_tbl .table tr .product_description, .cart_left .cart_tbl .table tr .product_price, .cart_left .cart_tbl .table tr .product_quantity, .cart_left .cart_tbl .table tr .product_subtotal, .cart_left .cart_tbl .table tr .product_remove, .cart_left .cart_tbl .table tr .product_stock{ padding-left: 0;}
    .cart_tbl .cart_qty{ margin: 0 0 0 auto;}
    .cart_recent_items .prd_grid ul li{ width: calc(50% - 10px);}
    .cart_summary{ padding: 20px 0 0;}
    .ohFilter{ position: static; padding-top: 15px;}
    .news_article_list ul li .nal_img{ width: 100%;}
    .news_article_list ul li .nal_content{ width: 100%; padding: 20px 0 0;}

    .quick_cart_tbl .addPrdTitle{ display: block;}
    .quick_cart_tbl .QuickSearchItem{ width: 100%; position: relative;}
    .quick_cart_tbl .cart_tbl .table tbody tr td.product_thumbnail{ min-width: 100%;}
    .quick_cart_tbl .quick_button{ padding: 0 0 20px;}
    .changeAddressModal .lrinner{ padding: 20px;}
    tr.invalid-product td{padding: 25px 10px!important;}
    
}
@media only screen and (max-width: 500px) {

    .header_inner{ padding-bottom: 50px;}
    .logo img{ max-width: 200px;}
    .header_right > ul > li{ padding-left: 7px;}
    .header_right > ul > li > a .icon{ width: 30px; height: 30px;}
    .header_right > ul > li > a .icon img{ max-width: 16px;}
    .header_right > ul > li > a .cart_count{ font-size: 12px; font-weight: 500; width: 18px; height: 18px; left: 15px; top: -13px;}
    .header_right > ul > li.navbar_toggler{ padding-left: 10px;}
    .searchingform input{ padding: 6px 15px;}
    .searchingform .search_close_icon{ right: 40px;}
    .navbar-toggler{ width: 30px; top: -59px;}
    .navbar-toggler span{ width: 30px;}
    .navbar_toggler .m-menu__toggle a{ width: 30px;}
    .top_category ul li{ width: 33.33%; padding-top: 30px;}
    .pkbx figure img{ aspect-ratio: 1/0.5; height: auto;}
    .summary_bx .btn_row{ gap: 14px;}
    .summary_bx .btn_row a{ width: 100%;}
    .contact_details{ padding: 25px 15px; margin: 0 -10px; border-radius: 0; overflow: hidden;}
    .contact_details .sales_team{ padding: 15px;}
    .cdCall a .text{ font-size: 14px;}
    
    .pdRight h1{ font-size: 24px;}
    .pdRight .price_rating .price{ font-size: 24px;}
    .pdRight .prd_rating img{ width: 30px;}
    .pdTbs .nav-tabs li .nav-link{ font-size: 14px; padding: 8px 18px;}
    .prd_grid ul li .view_variations_btn .button2{ min-width: 80px; line-height: 44px; font-size: 16px;}
}
@media only screen and (max-width: 450px) {
    
    .clproduct ul li .categoryname_row{ font-size: 14px;}
    .prd_grid ul li .add_qty{ flex-wrap: wrap;}
    .prd_grid ul li .qty_plusminus{ width: 100%; justify-content: space-between; margin-right: 0;}
    .prd_grid ul li .add_btn{ padding-top: 15px; width: 100%;}
    .prd_grid ul li .add_btn .button2{ display: block;}
    .prd_grid ul li .productThumb{ min-height: 160px;}
    .tbSorter .toolbar_sorter .perpage{ display: none;}
    .aged_details ul li{ width: calc(50% - 5px);}
    .aged_details .form-check .form-check-label{ padding-left: 8px; margin-right: 8px;}
    .aged_details .form-check .form-control{ width: 108px; margin-left: auto;}
    .aged_details .form-check a{ margin-left: auto; padding-top: 10px;}
    .account_details .button2{ width: 100%; padding: 0 15px; font-size: 19px;}
}
@media only screen and (max-width: 413px) {

    .logo img{ max-width: 180px;}
    /* .header_right > ul > li{ padding-left: 5px;}
    .header_right > ul > li a .icon{ width: 28px; height: 28px;}
    .header_right > ul > li a .icon img{ max-width: 14px;} */
    .navbar-toggler{ top: -55px;}
    .top_category ul li .tcName{ font-size: 13px;}
    .pdRight .qty_plusminus{ margin-right: 20px; min-width: 150px;}
    .top_bar ul li{ font-size: 12px;}
    .button4{ padding: 0 10px;}
    .tbSorter .toolbar_sorter select{ max-width: 150px; font-size: 14px;}
    .afterLogin_menu a{ padding: 0 2px;}
}

.invalid-text{color: #eb3434;text-align: justify;}
tr.invalid-product td{
  background-color: #eb34341a;
  border: 0;
}
.disable_cursor button, .disable_cursor input{cursor: no-drop!important;pointer-events: none;}
.invalid-product td{
  /* border: 0!important; */
}